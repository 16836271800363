import React, { useState } from "react";
import { PDFDocument } from "pdf-lib";

function App() {
  const states = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];

  const [isBuyerInfoVisible, setBuyerInfoVisible] = useState(false); // State to manage visibility

  const toggleBuyerInfo = () => {
    setBuyerInfoVisible((prev) => !prev);
  };

  // const createSignatureImage = async (text) => {
  //   const canvas = document.createElement("canvas");
  //   canvas.width = 400;
  //   canvas.height = 100;
  //   const ctx = canvas.getContext("2d");

  //   // Style the signature
  //   ctx.fillStyle = "white";
  //   ctx.fillRect(0, 0, canvas.width, canvas.height);
  //   ctx.font = "bold 24px Arial";
  //   ctx.fillStyle = "black";
  //   ctx.fillText(text, 10, 50);

  //   return new Promise((resolve) => {
  //     canvas.toBlob((blob) => {
  //       const reader = new FileReader();
  //       reader.onload = () => resolve(reader.result);
  //       reader.readAsArrayBuffer(blob);
  //     });
  //   });
  // };

  const formatDate = (date) => {
    const d = new Date(date);
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(d.getDate()).padStart(2, "0");
    const year = d.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const [formData, setFormData] = useState({
    date: formatDate(new Date()), // Default to today's date
    sellerName: "",
    odoDisclosureName: "",
    odoName: "",
    sellerAddress: "",
    sellerCity: "",
    sellerState: "TX",
    sellerZip: "",
    sellerPhone: "",
    buyerName: "Earth MotorCars",
    buyerAddress: "3216 Kellway Dr",
    buyerCity: "Carrollton",
    buyerState: "TX",
    buyerZip: "75006",
    buyerPhone: "2144839040",
    year: "",
    make: "",
    model: "",
    vin: "",
    color: "",
    odoBody: "",
    miles: "",
    cash: "",
    tradeIn: "",
    unpaid: "",
    payoff: "",
    cashDown: "",
    subtotal: "",
    balance: "",
    total: "",
    check1: false,
    check2: false,
    check3: false,
  });

  const [pdfBlob, setPdfBlob] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleGeneratePDF = async () => {
    try {
      const templateUrl = "/template-form-sig.pdf"; // Path to the template PDF in the public folder
      const templateBytes = await fetch(templateUrl).then((res) => res.arrayBuffer());

      const pdfDoc = await PDFDocument.load(templateBytes);
      const form = pdfDoc.getForm();

      // Fill out form fields
      Object.keys(formData).forEach((key) => {
        try {
          const field = form.getTextField(key);
          if (field) {
            field.setText(formData[key] || "");
          }
        } catch (err) {
          console.warn(`Field "${key}" is not a text field. Skipping...`);
        }
      });

      // // Handle signature fields
      // if (formData.buyerSignature) {
      //   const pages = pdfDoc.getPages();
      //   const firstPage = pages[0];

      //   const signatureText = formData.buyerSignature;
      //   const signatureImageBytes = await createSignatureImage(signatureText);
      //   const signatureImage = await pdfDoc.embedPng(signatureImageBytes);

      //   // Process signature1
      //   const signature1Field = form.getSignature("signature1");
      //   if (signature1Field) {
      //     const widget1 = signature1Field.acroField.getWidgets()[0];
      //     const { x, y, width, height } = widget1.getRectangle();

      //     form.removeField(signature1Field); // Remove the signature field
      //     firstPage.drawImage(signatureImage, { x, y, width, height });
      //   } else {
      //     console.warn("Signature1 field not found.");
      //   }

      //   // Process signature2
      //   const signature2Field = form.getSignature("signature2");
      //   console.log(signature2Field);
      //   if (signature2Field) {
      //     const widget2 = signature2Field.acroField.getWidgets()[0];
      //     const { x, y, width, height } = widget2.getRectangle();

      //     form.removeField(signature2Field); // Remove the signature field
      //     firstPage.drawImage(signatureImage, { x, y, width, height });
      //   } else {
      //     console.warn("Signature2 field not found.");
      //   }
      // }

      if (formData.date) {
        const dateField = form.getTextField("date");
        const odoDate1 = form.getTextField("odoDate1");
        const odoDate2 = form.getTextField("odoDate2");
        const payoffDate = form.getTextField("payoffDate");
        if (dateField) dateField.setText(formData.date);
        if (odoDate1) odoDate1.setText(formData.date);
        if (odoDate2) odoDate2.setText(formData.date);
        if (payoffDate) payoffDate.setText(formData.date);
      }

      if (formData.check1) {
        const check1 = form.getCheckBox("check1");
        if (check1) check1.check(); // Mark 'check1' if "Not Actual Mileage" is selected
      } else {
        const check1 = form.getCheckBox("check1");
        if (check1) check1.uncheck(); // Uncheck if not selected
      }

      if (formData.check2) {
        const check2 = form.getCheckBox("check2");
        if (check2) check2.check(); // Mark 'check2' if "Mileage in Excess of Mechanical Limits" is selected
      } else {
        const check2 = form.getCheckBox("check2");
        if (check2) check2.uncheck(); // Uncheck if not selected
      }

      // Additional logic for fields with shared input
      if (formData.year) {
        const yearField = form.getTextField("year");
        const odoYearField = form.getTextField("odoYear");
        const payoffYearField = form.getTextField("payoffYear");
        if (yearField) yearField.setText(formData.year);
        if (odoYearField) odoYearField.setText(formData.year);
        if (payoffYearField) payoffYearField.setText(formData.year);
      }

      if (formData.make) {
        const makeField = form.getTextField("make");
        const odoMakeField = form.getTextField("odoMake");
        const payoffMakeField = form.getTextField("payoffMake");
        if (makeField) makeField.setText(formData.make);
        if (odoMakeField) odoMakeField.setText(formData.make);
        if (payoffMakeField) payoffMakeField.setText(formData.make);
      }

      if (formData.model) {
        const modelField = form.getTextField("model");
        const odoModelField = form.getTextField("odoModel");
        const payoffModelField = form.getTextField("payoffModel");
        if (modelField) modelField.setText(formData.model);
        if (odoModelField) odoModelField.setText(formData.model);
        if (payoffModelField) payoffModelField.setText(formData.model);
      }

      if (formData.vin) {
        const vinField = form.getTextField("vin");
        const odoVinField = form.getTextField("odoVin");
        const payoffVinField = form.getTextField("payoffVin");
        if (vinField) vinField.setText(formData.vin);
        if (odoVinField) odoVinField.setText(formData.vin);
        if (payoffVinField) payoffVinField.setText(formData.vin);
      }

      if (formData.miles) {
        const milesField = form.getTextField("miles");
        const odoMiles = form.getTextField("odoReading");
        if (milesField) milesField.setText(formData.miles);
        if (odoMiles) odoMiles.setText(formData.miles);
      }

      if (formData.sellerPhone) {
        // const sellerPhoneField = form.getTextField("sellerPhone");
        const contactTelephone = form.getTextField("contactTelephone");
        // if (sellerPhoneField) sellerPhoneField.setText(formData.sellerPhone);
        if (contactTelephone) contactTelephone.setText(formData.sellerPhone);
      }

      if (formData.sellerName) {
        const sellerNameField = form.getTextField("sellerName");
        const sellerPrintedNameField = form.getTextField("sellerPrintedName");
        const odoSellerNameField = form.getTextField("odoSellerName");
        const odoPrintedNameField = form.getTextField("odoPrintedName");
        const payoffCustomerNameField = form.getTextField("payoffCustomerName");
        const payoffCustomerOneField = form.getTextField("payoffCustomerOne");
        if (sellerNameField) sellerNameField.setText(formData.sellerName);
        if (sellerPrintedNameField) sellerPrintedNameField.setText(formData.sellerName);
        if (odoSellerNameField) odoSellerNameField.setText(formData.sellerName);
        if (odoPrintedNameField) odoPrintedNameField.setText(formData.sellerName);
        if (payoffCustomerNameField) payoffCustomerNameField.setText(formData.sellerName);
        if (payoffCustomerOneField) payoffCustomerOneField.setText(formData.sellerName);
      }
      if (formData.odoDisclosureName) {
        const odoPrintedNameField = form.getTextField("odoPrintedName");
        if (odoPrintedNameField) odoPrintedNameField.setText(formData.odoDisclosureName);
      }
      if (formData.odoName) {
        const odoSellerNameField = form.getTextField("odoSellerName");
        if (odoSellerNameField) odoSellerNameField.setText(formData.odoName);
      }

      if (formData.buyerName) {
        const buyerNameField = form.getTextField("buyerName");
        const buyerPrintedNameField = form.getTextField("buyerPrintedName");
        const odoBuyerFirmField = form.getTextField("odoBuyerFirm");
        const payoffDealershipNameField = form.getTextField("payoffDealershipName");
        if (buyerNameField) buyerNameField.setText(formData.buyerName);
        if (buyerPrintedNameField) buyerPrintedNameField.setText(formData.buyerName);
        if (odoBuyerFirmField) odoBuyerFirmField.setText(formData.buyerName);
        if (payoffDealershipNameField) payoffDealershipNameField.setText(formData.buyerName);
      }

      if (formData.buyerAddress) {
        const buyerAddressField = form.getTextField("buyerAddress");
        const odoBuyerAddressField = form.getTextField("odoBuyerAddress");
        if (buyerAddressField) buyerAddressField.setText(formData.buyerAddress);
        if (odoBuyerAddressField) odoBuyerAddressField.setText(formData.buyerAddress);
      }

      if (formData.sellerAddress) {
        const sellerAddressField = form.getTextField("sellerAddress");
        const odoSellerAddressField = form.getTextField("odoAddress");
        if (sellerAddressField) sellerAddressField.setText(formData.sellerAddress);
        if (odoSellerAddressField) odoSellerAddressField.setText(formData.sellerAddress);
      }

      if (formData.buyerCity) {
        const buyerCityField = form.getTextField("buyerCity");
        const odoBuyerCityField = form.getTextField("odoBuyerCity");
        if (buyerCityField) buyerCityField.setText(formData.buyerCity);
        if (odoBuyerCityField) odoBuyerCityField.setText(formData.buyerCity);
      }

      if (formData.sellerCity) {
        const sellerCityField = form.getTextField("sellerCity");
        const odoSellerCityField = form.getTextField("odoCity");
        if (sellerCityField) sellerCityField.setText(formData.sellerCity);
        if (odoSellerCityField) odoSellerCityField.setText(formData.sellerCity);
      }

      if (formData.buyerState) {
        const buyerStateField = form.getTextField("buyerState");
        const odoBuyerStateField = form.getTextField("odoBuyerState");
        if (buyerStateField) buyerStateField.setText(formData.buyerState);
        if (odoBuyerStateField) odoBuyerStateField.setText(formData.buyerState);
      }

      if (formData.sellerState) {
        const sellerStateField = form.getTextField("sellerState");
        const odoSellerStateField = form.getTextField("odoState");
        if (sellerStateField) sellerStateField.setText(formData.sellerState);
        if (odoSellerStateField) odoSellerStateField.setText(formData.sellerState);
      }

      if (formData.buyerZip) {
        const buyerZipField = form.getTextField("buyerZip");
        const odoBuyerZipField = form.getTextField("odoBuyerZip");
        if (buyerZipField) buyerZipField.setText(formData.buyerZip);
        if (odoBuyerZipField) odoBuyerZipField.setText(formData.buyerZip);
      }

      if (formData.sellerZip) {
        const sellerZipField = form.getTextField("sellerZip");
        const odoSellerZipField = form.getTextField("odoZip");
        if (sellerZipField) sellerZipField.setText(formData.sellerZip);
        if (odoSellerZipField) odoSellerZipField.setText(formData.sellerZip);
      }

      if (formData.payoff) {
        // Parse numeric values to ensure calculations work as expected
        const cash = parseFloat(formData.cash || 0);
        const cashDown = parseFloat(formData.cashDown || 0);
        const payoff = parseFloat(formData.payoff || 0);

        // Perform the calculations
        const subtotal = cash - payoff;
        const balance = subtotal - cashDown;

        // Get fields and populate them with calculated values
        const cashField = form.getTextField("cash");
        const tradeInField = form.getTextField("tradeIn");
        const cashDownField = form.getTextField("cashDown");
        const payoffField = form.getTextField("payoff");
        const subtotalField = form.getTextField("subtotal");
        const balanceField = form.getTextField("balance");
        const totalField = form.getTextField("total");
        const lienPayoff = form.getTextField("lienPayoff");

        if (cashField) cashField.setText(cash.toFixed(2));
        if (tradeInField) tradeInField.setText(cash.toFixed(2)); // always the same as cash
        if (cashDownField) cashDownField.setText(cashDown.toFixed(2));
        if (payoffField) payoffField.setText(payoff.toFixed(2));
        if (subtotalField) subtotalField.setText(subtotal.toFixed(2));
        if (balanceField) balanceField.setText(balance.toFixed(2));
        if (totalField) totalField.setText(balance.toFixed(2));
        if (lienPayoff) lienPayoff.setText(payoff.toFixed(2));
      } else {
        const cashField = form.getTextField("cash");
        const cashDownField = form.getTextField("cashDown");
        const tradeInField = form.getTextField("tradeIn");
        const subtotalField = form.getTextField("subtotal");
        const balanceField = form.getTextField("balance");
        const totalField = form.getTextField("total");

        const cash = parseFloat(formData.cash || 0);
        const cashDown = parseFloat(formData.cashDown || 0);

        const balance = cash - cashDown;

        if (cashField) cashField.setText(cash.toFixed(2));
        if (tradeInField) tradeInField.setText(cash.toFixed(2)); // always the same as cash
        if (cashDownField) cashDownField.setText(cashDown.toFixed(2));
        if (subtotalField) subtotalField.setText(cash.toFixed(2));
        if (balanceField) balanceField.setText(balance.toFixed(2));
        if (totalField) totalField.setText(balance.toFixed(2));
      }
      // if exempt box checked, overwrite mileage
      if (formData.check3) {
        const milesField = form.getTextField("miles");
        const odoMiles = form.getTextField("odoReading");
        if (milesField) milesField.setText("EXEMPT");
        if (odoMiles) odoMiles.setText("EXEMPT");
      }

      // form.flatten();

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      setPdfBlob(blob);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const handleDownloadPDF = () => {
    if (pdfBlob) {
      const timestamp = new Date().toISOString().replace(/[-:.]/g, ""); // Generate a timestamp in the format YYYYMMDDTHHMMSS
      const filename = `earth-${timestamp}.pdf`;

      const url = URL.createObjectURL(pdfBlob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const renderInputField = (label, name, type = "text", isSelect = false) => {
    if (isSelect) {
      return (
        <div className="mb-4">
          <label htmlFor={name} className="block text-sm font-medium text-gray-700">
            {label}
          </label>
          <select
            id={name}
            name={name}
            value={formData[name]}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          >
            {states.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
        </div>
      );
    }

    return (
      <div className="mb-4">
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        <input
          type={type}
          id={name}
          name={name}
          value={formData[name]}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        />
      </div>
    );
  };
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-4xl">
        <h1 className="text-2xl font-bold mb-4">Earth MotorCars Form Generator</h1>
        <form onSubmit={(e) => e.preventDefault()}>
          {/* Buyer Information Section */}
          <h2 className="text-xl font-semibold mt-8 mb-4 border-b pb-2 flex justify-between">
            <span>Buyer Information</span>
            <button type="button" className="text-blue-500 hover:underline" onClick={toggleBuyerInfo}>
              {isBuyerInfoVisible ? "Hide" : "Edit"}
            </button>
          </h2>
          {isBuyerInfoVisible && (
            <div className="grid grid-cols-2 gap-4">
              {renderInputField("Name", "buyerName")}
              {renderInputField("Address", "buyerAddress")}
              {renderInputField("City", "buyerCity")}
              {renderInputField("State", "buyerState", "text", true)}
              {renderInputField("ZIP", "buyerZip", "number")}
              {renderInputField("Phone", "buyerPhone", "tel")}
            </div>
          )}

          {/* Seller Information Section */}
          <h2 className="text-xl font-semibold mb-4 border-b pb-2">Seller Information</h2>
          <div className="grid grid-cols-2 gap-4">
            {renderInputField("Name", "sellerName")}
            {renderInputField("Address", "sellerAddress")}
            {renderInputField("City", "sellerCity")}
            {renderInputField("State", "sellerState", "text", true)}
            {renderInputField("ZIP", "sellerZip", "number")}
            {renderInputField("Phone", "sellerPhone", "tel")}
            <i>If name for Odometer Disclosure different than seller</i>
            <br></br>
            {renderInputField("Odometer Seller/Agent Name", "odoName")}
            {renderInputField("Odometer Printed Name", "odoDisclosureName")}
          </div>

          {/* Car Information Section */}
          <h2 className="text-xl font-semibold mt-8 mb-4 border-b pb-2">Vehicle</h2>
          <div className="grid grid-cols-2 gap-4">
            {renderInputField("Year", "year", "number")}
            {renderInputField("Make", "make")}
            {renderInputField("Model", "model")}
            {renderInputField("VIN", "vin")}
            {renderInputField("Color", "color")}
            {renderInputField("Miles", "miles", "number")}
            {renderInputField("Body Style", "odoBody")}
          </div>

          {/* Pricing Section */}
          <h2 className="text-xl font-semibold mt-8 mb-4 border-b pb-2">Pricing</h2>
          <div className="grid grid-cols-2 gap-4">
            {renderInputField("Cash Price", "cash", "number")}
            {renderInputField("Trade-In Payoff", "payoff", "number")}
            {renderInputField("Cash Down Payment", "cashDown", "number")}
          </div>

          {/* Miscellaneous Section */}
          <h2 className="text-xl font-semibold mt-8 mb-4 border-b pb-2">Miscellaneous</h2>
          <div className="grid grid-cols-2 gap-4">
            {/* Date Selector */}
            <div className="mb-4">
              <label htmlFor="date" className="block text-sm font-medium text-gray-700">
                Date
              </label>
              <input
                type="date"
                id="date"
                name="date"
                value={formData.date.split("/").reverse().join("-")} // Convert MM/DD/YYYY to YYYY-MM-DD
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            {/* Buyer Signature
            <div className="mb-4">
              <label htmlFor="buyerSignature" className="block text-sm font-medium text-gray-700">
                Buyer Signature
              </label>
              <input
                type="text"
                id="buyerSignature"
                name="buyerSignature"
                value={formData.buyerSignature || ""}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="Enter your signature"
              />
            </div> */}

            {/* Checkboxes for Mileage */}
            <div className="mb-4">
              <fieldset>
                <legend className="text-sm font-medium text-gray-700">Mileage Options</legend>
                <div className="mt-2 space-y-2">
                  <div className="flex items-center">
                    <input
                      id="check1"
                      name="check1"
                      type="checkbox"
                      checked={!!formData.check1}
                      onChange={(e) => setFormData({ ...formData, check1: e.target.checked })}
                      className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                    />
                    <label htmlFor="check1" className="ml-3 block text-sm text-gray-700">
                      Not Actual Mileage
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="check2"
                      name="check2"
                      type="checkbox"
                      checked={!!formData.check2}
                      onChange={(e) => setFormData({ ...formData, check2: e.target.checked })}
                      className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                    />
                    <label htmlFor="check2" className="ml-3 block text-sm text-gray-700">
                      Mileage in Excess of Mechanical Limits
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="check3"
                      name="check3"
                      type="checkbox"
                      checked={!!formData.check3}
                      onChange={(e) => setFormData({ ...formData, check3: e.target.checked })}
                      className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                    />
                    <label htmlFor="check3" className="ml-3 block text-sm text-gray-700">
                      Exempt Mileage
                    </label>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>

          <button
            type="button"
            onClick={handleGeneratePDF}
            className="mt-8 w-full bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 focus:ring-4 focus:ring-blue-300"
          >
            Generate
          </button>

          {pdfBlob && (
            <button
              type="button"
              onClick={handleDownloadPDF}
              className="mt-4 w-full bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 focus:ring-4 focus:ring-green-300"
            >
              Download PDF
            </button>
          )}
        </form>
      </div>
    </div>
  );
}

export default App;
